<template>

  <div class="genelal-box ">
    <div style="display: flex;height:100%;">
      <div class="left_area">
        <div class="left_top ranikb">
          <dv-border-box-8 :dur="10">
            <div class="column_l">
              <div class="title m20">
                <p>
                  上课数据
                  <dv-decoration-2
                    style="width: 100%; height: 5px"
                    :color="['#ffffff']"
                  />
                </p>
              </div>
              <div class="h10"></div>
              <div class="row_layout">
                <el-select
                  ref="monthSel"
                  v-model="month_value"
                  :popper-append-to-body="false"
                  class="leftcenter"
                  @change="changeMonth"
                >
                  <el-option
                    v-for="item in month_options"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
                <div style="flex: 1"></div>
                <el-select
                  v-model="gradeValue"
                  :popper-append-to-body="false"
                  class="rightcenter"
                  @change="changeGradeLeft"
                >
                  <el-option
                    v-for="item in grade_options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <img
                class="left_top_line"
                style="padding:0;"
                src="../../../../assets/img/zhSchool/ranking/Pic_Line.png"
              />

              <div>
                <div class="row_layout m14">
                  <div class="left_top_text flex">本月上课人数</div>
                  <div class="left_top_text">
                    {{ classData.allPeopleNumMonth }}
                  </div>
                </div>
                <div class="h10"></div>
                <el-progress
                  style="
                   width:100%
                  "
                  :percentage="percentage1"
                  :color="customColor1"
                  :stroke-width="7"
                  :show-text="false"
                ></el-progress>
                <div class="row_layout  m10">
                  <div class="left_top_text flex">本月上课人次</div>
                  <div class="left_top_text">
                    {{ classData.allPeopleTimesMonth }}
                  </div>
                </div>
                <div class="h10"></div>
                <el-progress
                  style="
                   width:100%
                  "
                  :percentage="percentage2"
                  :color="customColor1"
                  :stroke-width="7"
                  :show-text="false"
                ></el-progress>
                <div class="row_layout  m10">
                  <div class="grade_one_text flex">累计上课人数</div>
                  <div class="grade_one_text">{{ classData.allPeopleNum }}</div>
                </div>
                <div class="h10"></div>
                <el-progress
                  style="
                   width:100%
                  "
                  :percentage="100"
                  :color="customColor2"
                  :stroke-width="7"
                  :show-text="false"
                ></el-progress>
                <div class="row_layout  m10">
                  <div class="grade_one_text flex">累计上课人次</div>
                  <div class="grade_one_text">
                    {{ classData.allPeopleTimes }}
                  </div>
                </div>
                <div class="h10"></div>
                <el-progress
                  style="
                   width:100%
                  "
                  :percentage="100"
                  :color="customColor2"
                  :stroke-width="7"
                  :show-text="false"
                ></el-progress>
              </div>
              <div class="h20"></div>
              <div
                style=""
                class="position-absolute_box"
              >
                <div class="row_layout ">
                  <div class="month_value flex">班级排名</div>
                </div>
                <div class="h20"></div>
                <div class="row_layout ">
                  <div class="column_layout">
                    <div class="position_rel">
                      <dv-decoration-9 :color="['RGBA(204, 174, 110, 1)']">
                        {{ rateForamt1 }}
                      </dv-decoration-9>
                      <!-- <el-progress
                        type="circle"
                        :percentage="percentage3"
                        :color="customColor2"
                        :width="74"
                        :show-text="false"
                        style="position: absolute"
                      ></el-progress>
                      <div
                        class="grade_one_text num_align"
                        style="width: 74px; height: 74px; position: absolute"
                      >
                        {{ rateForamt1 }}
                      </div> -->
                    </div>
                    <div
                      class="grade_one_text m10"
                      style="max-width: 80px"
                    >
                      {{ name1 }}
                    </div>
                  </div>
                  <div class="column_layout">
                    <div class="position_rel1">
                      <dv-decoration-9 :color="['RGBA(134, 222, 225, 1)']">
                        {{ rateForamt2 }}
                      </dv-decoration-9>
                      <!-- <el-progress
                        type="circle"
                        :percentage="percentage4"
                        :color="customColor3"
                        :width="74"
                        :show-text="false"
                        style="position: absolute"
                      ></el-progress>
                      <div
                        class="grade_two_text num_align"
                        style="width: 74px; height: 74px; position: absolute"
                      >
                        {{ rateForamt2 }}
                      </div> -->
                    </div>
                    <div
                      class="grade_two_text m10"
                      style="max-width: 80px"
                    >
                      {{ name2 }}
                    </div>
                  </div>
                  <div class="column_layout">
                    <div class="position_rel1">
                      <dv-decoration-9 :color="['RGBA(134, 222, 225, 1)']">
                        {{ rateForamt3 }}
                      </dv-decoration-9>
                      <!-- <el-progress
                        type="circle"
                        :percentage="percentage5"
                        :color="customColor3"
                        :width="74"
                        :show-text="false"
                        style="position: absolute"
                      ></el-progress>
                      <div
                        class="grade_two_text num_align"
                        style="width: 74px; height: 74px; position: absolute"
                      >
                        {{ rateForamt3 }}
                      </div> -->
                    </div>
                    <div
                      class="grade_two_text m10"
                      style="max-width: 80px"
                    >
                      {{ name3 }}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </dv-border-box-8>
        </div>

        <div class="left_bottoms">
          <dv-border-box-8 :dur="10">
            <div class="column_l">
              <div class="title m20">
                <p>
                  家庭互动
                  <dv-decoration-2
                    style="width: 100%; height: 5px"
                    :color="['#ffffff']"
                  />
                </p>
              </div>
              <div class="left_bottom_positon">
                <div class="left_bottom">
                  <div class="disple_center">
                    <div class="left_bottom_title">家庭测试录入</div>
                    <div class="row_layout">
                      <div class="left_bottom_num ">
                        {{ familyTestNum }}
                      </div>
                      <img src="../../../../assets/img/zhSchool/ranking/Icon_HD_LR.png" />
                    </div>
                  </div>

                </div>
                <img
                  class="left_top_line"
                  src="../../../../assets/img/zhSchool/ranking/Pic_Line.png"
                />
                <div class="left_bottom">
                  <div class="disple_center">
                    <div class="left_bottom_title">家庭报告数量</div>
                    <div class="row_layout">
                      <div class="left_bottom_num ">
                        {{ familyReportNum }}
                      </div>
                      <img src="../../../../assets/img/zhSchool/ranking/Icon_HD_BG.png" />
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </dv-border-box-8>
        </div>
      </div>
      <div class="middle_area">
        <div class="middle_top-box">
          <div class="middle_top">
            <dv-border-box-8
              :dur="10"
              style="height:100%"
            >
              <div class="column_l">
                <div class="title m20">
                  <p>
                    园所人数
                    <dv-decoration-2
                      style="width: 100%; height: 5px"
                      :color="['#ffffff']"
                    />
                  </p>
                </div>
                <div
                  style="position:absolute;"
                  class="height_middle_top_p1"
                >
                  <img
                    style="width: 100%;height:100%;"
                    src="../../../../assets/img/zhSchool/ranking/Pic_PT_YSRS2.png"
                    class="framesImg"
                  />
                  <div class="middle_top_p1 framesImg">
                    <div class="name_text">园所人数</div>
                    <div class="value_text">
                      {{ peopleNumerBean.onLineSchoolCount }}
                    </div>
                  </div>
                  <div class="middle_top_p2 framesImg">
                    <div class="name_text">班级数量</div>
                    <div class="value_text">
                      {{ peopleNumerBean.onLineGradeCount }}
                    </div>
                  </div>
                  <div class="middle_top_p3 framesImg">
                    <div class="name_text">累计人次</div>
                    <div class="value_text">
                      {{ peopleNumerBean.registSchoolCount }}
                    </div>
                  </div>
                  <div class="middle_top_p4 framesImg">
                    <div class="name_text">教师数量</div>
                    <div class="value_text">
                      {{ peopleNumerBean.onLineTeacherCount }}
                    </div>
                  </div>
                  <div class="middle_top_p5 framesImg">
                    <div class="name_text">幼儿数量</div>
                    <div class="value_text">
                      {{ peopleNumerBean.onLineStudentCount }}
                    </div>
                  </div>
                </div>

              </div>
            </dv-border-box-8>
          </div>
        </div>
        <div class="middle_bottom">
          <dv-border-box-8 :dur="10">
            <div class="column_l">
              <div class="title m20">
                <p>
                  活力积分
                  <dv-decoration-2
                    style="width: 100%; height: 5px"
                    :color="['#ffffff']"
                  />
                </p>
              </div>
              <div class="echar-map">
                <mapChart
                  :province="province"
                  :city="city"
                />
              </div>

            </div>
          </dv-border-box-8>
        </div>
      </div>
      <div class="left_area">
        <div class="right_top_box">
          <dv-border-box-8 :dur="10">
            <div class="right_top">
              <div class="title m20">
                <p>
                  足球特色幼儿园
                  <dv-decoration-2
                    style="width: 100%; height: 5px"
                    :color="['#ffffff']"
                  />
                </p>
              </div>
              <div class="right_top_box_bottom">
                <div
                  v-if="
                    schoolCreditType == 3 ||
                    schoolCreditType == 4 ||
                    schoolCreditType == 5
                  "
                  class="top_image"
                >
                  <img
                    v-if="schoolCreditType == 3"
                    src="../../../../assets/img/zhSchool/ranking/Icon_ZTY.png"
                  />
                  <img
                    v-if="schoolCreditType == 4"
                    src="../../../../assets/img/zhSchool/ranking/Icon_SFY.png"
                  />
                  <img
                    v-if="schoolCreditType == 5"
                    src="../../../../assets/img/zhSchool/ranking/Icon_GYY.png"
                  />
                  <div
                    v-if="schoolCreditType == 3"
                    class="normal_title"
                  >
                    足特园
                  </div>
                  <div
                    v-if="schoolCreditType == 4"
                    class="column"
                  >
                    <div class="title">示范园试点</div>
                    <div class="rank_num">{{ rankNum }}</div>
                    <div class="hint_text">当前排名</div>
                  </div>
                  <div
                    v-if="schoolCreditType == 5"
                    class="normal_title"
                  >
                    公益园
                  </div>
                </div>
                <div class="row_layout m20">
                  <div
                    v-if="schoolCreditType == 4"
                    class="month_value"
                    style="margin-left: 30px"
                  >
                    示范数据
                  </div>
                  <div
                    v-else
                    class="month_value"
                    style="margin-left: 30px"
                  >
                    活跃数据
                  </div>
                  <div style="flex: 1"></div>
                  <el-select
                    v-model="provinceValue"
                    :popper-append-to-body="false"
                    style="margin-right: 20px"
                    @change="changProvince"
                  >
                    <el-option
                      v-for="item in province_options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="ranking-list-box">
                  <div class="ranking-item-name">
                    <ul>
                      <li>序号</li>
                      <li>园所名称</li>
                      <li>园所人数</li>
                      <li>活跃积分</li>
                    </ul>
                  </div>

                  <div
                    class="ranking-item-content"
                    v-if="rankingList.length > 7"
                  >
                    <vue-seamless-scroll
                      class="teacher-box-scroll"
                      :data="rankingList"
                      :class-option="plaqueOption"
                    >
                      <ul
                        v-for="(item, index) in rankingList"
                        :key="index"
                      >
                        <li>{{ index + 1 }}</li>
                        <li>{{ item.schoolName }}</li>
                        <li>{{ item.onStudentCount || 0 }}</li>
                        <li>{{ item.integralTotal || 0 }}</li>
                      </ul>
                    </vue-seamless-scroll>
                  </div>
                  <div
                    class="ranking-item-content"
                    v-else
                  >
                    <vue-seamless-scroll
                      class="teacher-box-scroll"
                      :data="rankingList"
                      :class-option="plaqueOption"
                    >
                      <ul
                        v-for="(item, index) in rankingList"
                        :key="index"
                      >
                        <li>{{ index + 1 }}</li>
                        <li>{{ item.schoolName }}</li>
                        <li>{{ item.onStudentCount || 0 }}</li>
                        <li>{{ item.integralTotal || 0 }}</li>
                      </ul>
                    </vue-seamless-scroll>
                  </div>
                </div>
              </div>

            </div>
          </dv-border-box-8>
        </div>

        <div class="left_bottoms">
          <dv-border-box-8 :dur="10">
            <div class="column_l">
              <div class="title m20">
                <p>
                  测试数据
                  <dv-decoration-2
                    style="width: 100%; height: 5px"
                    :color="['#ffffff']"
                  />
                </p>
              </div>
              <div class="eacher_botom_box">
                <div class="row_layout">
                  <el-select
                    v-model="testTypeValue"
                    :popper-append-to-body="false"
                    
                    @change="changeType"
                  >
                    <el-option
                      v-for="item in test_type_options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <div style="flex: 1"></div>
                  <el-select
                    v-model="gradeValue1"
                    :popper-append-to-body="false"
                    
                    @change="changeGradeRight"
                  >
                    <el-option
                      v-for="item in grade_options"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
                <img
                  
                  src="../../../../assets/img/zhSchool/ranking/Pic_Line.png"
                />
                <div class="row_layout  m14 right_bottom_bg1">
                  <div class="left_top_text flex">本月测试人数</div>
                  <div class="left_top_text">{{ monthTestNum }}</div>
                </div>

                <div class="row_layout  m14 right_bottom_bg2">
                  <div class="left_top_text flex">累计测试人数</div>
                  <div class="grade_one_text">{{ allTestNum }}</div>
                </div>
              </div>

              <radarChart
                :type="testType"
                :gradeId="gradeId1"
                :gradeType="gradeType1"
              />
            </div>
          </dv-border-box-8>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import "swiper/dist/css/swiper.css";
import mapChart from "@/components/zhSchoolRK/echart/bluemap";
import radarChart from "@/components/zhSchoolRK/echart/radar"; // 雷达

import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    radarChart,
    mapChart,
    vueSeamlessScroll,
  },
  data() {
    return {
      rankingList: [],
      month_value: "",
      month_options: [],
      gradeValue: "全部班级",
      gradeId: "",
      grade_options: [],
      percentage1: 0,
      customColor1: "RGBA(144, 230, 231, 1)",
      percentage2: 0,
      customColor2: "RGBA(204, 174, 110, 1)",
      customColor3: "RGBA(134, 222, 225, 1)",
      percentage3: 0,
      percentage4: 0,
      percentage5: 0,
      rateForamt1: "0.00%",
      rateForamt2: "0.00%",
      rateForamt3: "0.00%",
      name1: "",
      name2: "",
      name3: "",
      province: this.$store.state.schoolInfo.atteSchool.province, // 省份
      city: this.$store.state.schoolInfo.atteSchool.trueCity, // 城市
      provinceValue: "省",
      provinceId: "province",
      province_options: [
        {
          value: "province",
          label: "省",
        },
        {
          value: "city",
          label: "市",
        },
      ],
      testTypeValue: "体质测试",
      testType: "1",
      test_type_options: [
        {
          value: "1",
          label: "体质测试",
        },
        {
          value: "2",
          label: "运动测试",
        },
      ],
      gradeValue1: "全部班级",
      gradeId1: "",
      gradeType1: "0",
      testPer: 40,
      testAllPer: 80,
      peopleNumerBean: {},
      classData: {},
      classRanking: [],
      familyTestNum: 0,
      familyReportNum: 0,
      testPeopleNum: {
        baseNumMonth: 0,
        baseNum: 0,
        sportNumMonth: 0,
        sportNum: 0,
      },
      monthTestNum: 0,
      allTestNum: 0,
      schoolCreditType: 0,
      rankNum: 0,
    };
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // 加载默认主题颜色
    window.document.documentElement.setAttribute("data-theme", "base");
    let yy = new Date().getFullYear();

    let mm =
      new Date().getMonth() + 1 < 10
        ? "0" + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1;
    this.month_value = yy + "-" + mm;
    this.month_options = [
      {
        value: "01",
        label: yy + "-01",
      },
      {
        value: "02",
        label: yy + "-02",
      },
      {
        value: "03",
        label: yy + "-03",
      },
      {
        value: "04",
        label: yy + "-04",
      },
      {
        value: "05",
        label: yy + "-05",
      },
      {
        value: "06",
        label: yy + "-06",
      },
      {
        value: "07",
        label: yy + "-08",
      },
      {
        value: "09",
        label: yy + "-09",
      },
      {
        value: "10",
        label: yy + "-10",
      },
      {
        value: "11",
        label: yy + "-11",
      },
      {
        value: "12",
        label: yy + "-12",
      },
    ];
    this.init();
  },
  methods: {
    // 默认
    init() {
      this.getRankingGradeList();
      this.getRankingPeopleNum();
      this.getRankingClassData();
      this.geteRankingClassRankingData();
      this.geteRankingFamilyInteraction();
      this.geteRankingAreaList();
      this.geteRankingLevelList();
    },
    // 选择班级
    changeGradeLeft(item) {
      this.gradeId = item;
      this.getRankingClassData();
    },
    // 选择班级
    changeMonth(item) {
      this.month_value = item;
      this.getRankingClassData();
    },
    // 获取班级列表
    getRankingGradeList() {
      this.api.zhSchool.geteRankingGradeList().then((res) => {
        console.log(res.data);
        this.grade_options = res.data.data;
        this.gradeId1 = res.data.data[0].id;
        this.gradeValue1 = res.data.data[0].name;
        this.gradeType1 = res.data.data[0].type;
        this.geteRankingTestPeopleNum();
      });
    },
    //获取园所人数
    getRankingPeopleNum() {
      this.api.zhSchool.geteRankingPeopleNum().then((res) => {
        this.peopleNumerBean = res.data;
      });
    },
    //获取园所上课数据
    getRankingClassData() {
      this.api.zhSchool
        .geteRankingClassData({
          time: this.month_value,
          gradeId: this.gradeId,
        })
        .then((res) => {
          this.classData = res.data;
          this.percentage1 =
            (res.data.allPeopleNumMonth / res.data.allPeopleNum) * 100;
          this.percentage2 =
            (res.data.allPeopleTimesMonth / res.data.allPeopleTimes) * 100;
        });
    },
    //获取家庭互动
    geteRankingFamilyInteraction() {
      this.api.zhSchool.geteRankingFamilyInteraction().then((res) => {
        this.familyTestNum = res.data.familyInteractionTimes;
        this.familyReportNum = res.data.familyInteractionNums;
      });
    },
    //获取上课排名
    geteRankingClassRankingData() {
      this.api.zhSchool.geteRankingClassRankingData().then((res) => {
        this.classRanking = res.data.data;
        if (this.classRanking) {
          this.percentage3 = this.classRanking[0].rate * 100;
          this.percentage4 = this.classRanking[1].rate * 100;
          this.percentage5 = this.classRanking[2].rate * 100;
          this.rateForamt1 = this.classRanking[0].rateForamt;
          this.rateForamt2 = this.classRanking[1].rateForamt;
          this.rateForamt3 = this.classRanking[2].rateForamt;
          this.name1 = this.classRanking[0].name;
          this.name2 = this.classRanking[1].name;
          this.name3 = this.classRanking[2].name;
        }
      });
    },
    // 获取区域积分
    geteRankingAreaList() {
      this.api.zhSchool.geteRankingAreaList().then((res) => {
        this.classRanking = res.data.data;
      });
    },
    // 获取测试数据
    geteRankingTestPeopleNum() {
      this.api.zhSchool
        .geteRankingTestNum({
          gradeId: this.gradeId1,
        })
        .then((res) => {
          this.testPeopleNum = res.data;
          this.monthTestNum = res.data.baseNumMonth;
          this.allTestNum = res.data.baseNum;
        });
    },
    // 获取园所级别列表
    geteRankingLevelList() {
      this.api.zhSchool
        .geteRankingLevelList({
          scope: this.provinceId,
        })
        .then((res) => {
          this.schoolCreditType = res.data.currentSchool.schoolCreditType;
          this.rankNum = res.data.currentSchool.rank;
          if (
            this.schoolCreditType == 3 ||
            this.schoolCreditType == 4 ||
            this.schoolCreditType == 5
          ) {
            this.rankingList = res.data.levelIntegralList.splice(0, 5);
          } else {
            this.rankingList = res.data.levelIntegralList;
          }
        });
    },
    changProvince(val) {
      this.provinceId = val;
      this.geteRankingLevelList();
    },
    changeType(val) {
      this.testType = val;
      if (val == 1) {
        this.monthTestNum = this.testPeopleNum.baseNumMonth;
        this.allTestNum = this.testPeopleNum.baseNum;
      } else {
        this.monthTestNum = this.testPeopleNum.sportNumMonth;
        this.allTestNum = this.testPeopleNum.sportNum;
      }
    },
    changeGradeRight(val) {
      this.gradeId1 = val.id;
      this.gradeType1 = val.type;
      this.gradeValue1 = val.name;
      this.geteRankingTestPeopleNum();
    },
  },
  computed: {
    plaqueOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: this.rankingList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
};
</script>
<style lang="scss" scoped>
//@import "@/assets/style/ranking/_handle.scss";
.genelal-box {
  width: 100%;
  height: 100%;
  background: #0e1b4827;
  //border-radius: 16px;
  //padding: 15px 30px 0;
  box-sizing: border-box;
  position: relative;
}
.genelal-box-top-info {
  @include flex(row, space-between, center);
}
.ranking-list-box {
  padding: 15px 27px 0 23px;
  height: 80%;
  width:100%;
  box-sizing: border-box;
  .ranking-item-name {
    ul {
      display: flex;
      justify-content: space-around;
      height: 42px;
      background: #6486a630;
      li {
        flex: 1;
        text-align: center;
        line-height: 42px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #c3e8ff;
      }
    }
  }
  .ranking-item-content {
    width: 100%;
    height: 300px;
    overflow: hidden;
    ul {
      display: flex;
      justify-content: space-around;
      height: 42px;
      li {
        flex: 1;
        text-align: center;
        line-height: 42px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #a3d3e3;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height:42px;
      }
    }
    ul:nth-child(even) {
      background: #6486a630;
      li {
        color: #fff;
      }
    }
  }
}
.school-info-title {
  width: 313px;
  height: 52px;
  background-image: url("../../../../assets/img/zhSchool/one/Pic_Bg_Title_JXLN.png");
  background-size: cover;
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #c3e8ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.school-strength-box .swiper-container {
  width: 434px;
  height: 142px;
}
.school-strength-box .swiper-container .swiper-slide {
  width: 100%;
  height: 100%;
}
.left_area {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
}
.left_top {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 475px;
  // background-image: url("../../../../assets/img/zhSchool/ranking/Pic_Bg_ZQTSYEY2.png");
  // background-size: cover;
}
.left_bottoms {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 475px);
  position: relative;
  overflow: hidden;
}
.l40 {
  margin-left: 40px;
}
.m32 {
  margin-top: 32px;
}
.title {
  width: 100%;
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: white;
  display: flex;
  justify-content: center;
}
.m13 {
  margin-top: 1.5%;
}
.month_value {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c3e8ff;
  line-height: 18px;
}
.row_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
/deep/ .el-input__inner {
  width: 120px;
  background: transparent;
  border: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c3e8ff;
  text-align: right;
  appearance: none;

  -webkit-appearance: none;

  -moz-appearance: none;
}
/deep/ .el-icon-arrow-up:before {
  content: "";
  background: url(../../../../assets/img/zhSchool/ranking/Icon_Arrow_Down.png)
    center center no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/deep/ .el-select-dropdown__item.hover {
  background: #333333;
}
/deep/ .el-popper {
  background: #333333;
}

/deep/ .el-select-dropdown__item.hover {
  background: transparent;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c3e8ff;
}
.el-select-dropdown__item {
  background: transparent;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c3e8ff;
}
.left_top_line {
  display: block;
  padding: 0 28px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}
.flex {
  flex: 1;
}
.m17 {
  margin-top: 17px;
}
.left_top_text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: white;
}
/deep/ .el-progress-bar__outer {
  background-color: RGBA(58, 123, 138, 1);
  height: 7px !important;
}
.column_layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.position_rel {
  position: relative;
  /deep/ .el-progress-circle__track {
    stroke: RGBA(84, 106, 99, 1);
  }
}
.position_rel1 {
  position: relative;
  /deep/ .el-progress-circle__track {
    stroke: RGBA(58, 123, 138, 1);
  }
}
.position_abs {
  position: absolute;
  top: 15px;
  left: 0px;
}
.grade_one_text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffd686;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.grade_two_text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c3e8ff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.m10 {
  margin-top: 10px;
}
.m14 {
  margin-top: 14px;
}
.num_align {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.left_bottom {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 45%;

  .row_layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 322px;
    margin-top: 8px;

    justify-content: space-between;
  }
}
.center {
  align-items: center;
  justify-content: center;
}
.left_bottom_title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c3e8ff;
  line-height: 24px;
  padding-left:40px;
}
.left_bottom_num {
  font-size: 50px;
  font-family: Impact;
  font-weight: 400;
  color: #ffd686;
  width: 112px;
padding-left:40px;
box-sizing: border-box;
  
}
.middle_area {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.m20 {
  margin-top: 15px;
}
.column_l {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.middle_top-box {
  width: 100%;
  height: 475px;
}
.middle_top {
  position: relative;
  width: 100%;
  height: 100%;
  .bg_pos {
    position: absolute;
    top: 80px;
    left: 30px;
  }
  .middle_top_p1 {
    position: absolute;
    top: 50%;

    left: 50%;
    width: 80px;
    margin-left: -350px;
    text-align: center;
    margin-top: -75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .image1 {
      position: absolute;
      top: 10px;
      left: 48px;
    }
  }
  .name_text {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: white;
  }
  .value_text {
    font-size: 40px;
    font-family: Impact;
    font-weight: 400;
    color: #dff3ff;
    margin-top: 2px;
  }
  .middle_top_p2 {
    position: absolute;
    left: 50%;
    margin-left: -205px;
    top: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .middle_top_p3 {
    position: absolute;
    left: 50%;
    top: -30px;
    margin-left: -34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .middle_top_p4 {
    position: absolute;
    right: 50%;
    margin-right: -205px;
    top: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .middle_top_p5 {
    position: absolute;
    top: 50%;
    right: 50%;
    margin-right: -350px;
    margin-top: -75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .middle_top_p6 {
    position: absolute;
    left: 334px;
    top: 256px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.middle_bottom {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 475px);
}
.right_top {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 496px;
  // background-image: url("../../../../assets/img/zhSchool/ranking/Pic_Bg_ZQTSYEY2.png");
  // background-size: cover;
  .top_image {
    display: flex;
    flex-direction: row;
    height: 20%;
    padding-left: 30px;
    padding-right: 28px;
    padding-top: 10px;
    box-sizing: border-box;
    width: 100%;
    //background-image: url("../../../../assets/img/zhSchool/ranking/Pic_Bg_YSLB.png");
    background-size: cover;
    img {
      width: auto;
      height: 100%;
    }
    .column {
      display: flex;
      flex-direction: column;
      margin-left: 24px;
    }
    .normal_title {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #dbf5fd;
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 24px;
    }
    .title {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #dbf5fd;
      margin-top: 13px;
      line-height: 24px;
    }
    .rank_num {
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffd686;
      margin-top: 5px;
    }
    .hint_text {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a3d3e3;
      margin-top: 3px;
    }
  }
}
.right_bottom_bg1 {
  background-image: url("../../../../assets/img/zhSchool/ranking/Pic_Bg_BYCS.png");
  background-size: cover;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 17px;
  padding-right: 17px;
  display: flex;
}
.right_bottom_bg2 {
  background-image: url("../../../../assets/img/zhSchool/ranking/Pic_Bg_LJCS.png");
  background-size: cover;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 17px;
  padding-right: 17px;
  display: flex;
}
.framesImg {
  animation: bounce-down 1.5s linear infinite;
}
@keyframes bounce-down {
  25% {
    transform: translateY(-10px);
  }
  50%,
  100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
}
.right_top_box {
  height: 475px;
}
.eacher_botom_box{
  padding:0 35px 0 27px;
  img{
    
  }
}
</style>
<style lang="scss">
.ranikb .dv-border-box-8 .border-box-content {
  padding: 0 20px !important;
  box-sizing: border-box !important;
}
.h10 {
  height: 5px;
}
.position_rel,
.position_rel1 {
  width: 74px;
  height: 74px;
}
.position-absolute_box {
  width: 100%;
  box-sizing: border-box;
  padding-bottom:  20px !important;
}
.left_bottom_positon {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 0 20px 20px;

  height: calc(100% - 50px);
  .left_bottom {
    height: 49%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.echar-map {
  position: absolute;
  height: calc(100% - 70px);
  bottom: 5px;
  left: 0;
  width: 100%;
  overflow: hidden;
}
.height_middle_top_p1 {
  height: 350px;
  width: 100%;
  
  
  bottom:14px;
}
.right_top_box_bottom {
  display: flex;

  align-items: center;
  flex-direction: column;
  height: calc(100% - 80px);
  overflow: hidden;
  .row_layout {
    width: 100%;
  }
}
.disple_center {
  width: 100%;
  box-sizing: border-box;
  padding: 0 40px;
  flex-direction: column;
  align-items: center;
  //height:100%;
  //justify-content: center;
  display: flex;
  img {
    width: 68px;
    height: 68px;
    margin-right: 45px;
  }
  .left_bottom_title {
    width: 322px;

    box-sizing: border-box;
  }
}

</style>
<style lang="scss">
.rightcenter {
  .el-input--suffix .el-input__inner {
    text-align: right;
  }
  .el-input__suffix {
    right: 0 !important;
  }
}
.leftcenter {
  .el-input--suffix .el-input__inner {
    text-align: left;
    //width:108px;
  }
}
.eacher_botom_box{
.el-input--suffix .el-input__inner {
    text-align: left;
    
  }
}
</style>