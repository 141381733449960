<template>
  <div class="map-char">
    <div class="rand-left" v-if="isMapChina">
      <h2>{{ city != "" ? city : province }}</h2>
      <ul class="rand-list-title">
        <li>排名</li>
        <li>园所</li>
        <li>在线学员</li>
        <li>活力积分</li>
      </ul>
      <ul
        class="rand-list-item"
        v-for="(item, index) in leaderboardList"
        :key="index"
      >
        <li>{{ index + 1 }}</li>
        <li :title="item.schoolName">{{ item.schoolName }}</li>
        <li>{{ item.onStudentCount || 0 }}</li>
        <li>{{ item.integralTotal || 0 }}</li>
      </ul>
    </div>
    <div class="map-right" v-if="$store.state.superviseId">
      <div id="map" :style="styleObj" v-on-echart-resize></div>
    </div>
    <div class="map-right" v-else>
      <div id="map" :style="styleObj1" v-on-echart-resize></div>
    </div>
  </div>
</template>
<script>
import elementResizeDetectorMaker from "element-resize-detector";
import "../../../common/echart/chart.resize";
// 在此处引入对应的地图JSON数据
import china from "../../map/china.json";
import beijing from "../../map/beijing.json";
import shanxi from "../../map/shanxi.json";
import anhui from "../../map/anhui.json";
import chongqing from "../../map/chongqing.json";
import fujian from "../../map/fujian.json";
import gansu from "../../map/gansu.json";
import guangdong from "../../map/guangdong.json";
import guangxi from "../../map/guangxi.json";
import guizhou from "../../map/guizhou.json";
import hainan from "../../map/hainan.json";
import hebei from "../../map/hebei.json";
import hunan from "../../map/hunan.json";
import jiangxi from "../../map/jiangxi.json";
import jiangsu from "../../map/jiangsu.json";
import jilin from "../../map/jilin.json";
import liaoning from "../../map/liaoning.json";
import ningxia from "../../map/ningxia.json";
import nmeng from "../../map/nmeng.json";
import sanxi from "../../map/sanxi.json";
import shandong from "../../map/shandong.json";
import sichuan from "../../map/sichuan.json";
import tianjing from "../../map/tianjing.json";
import xinjiang from "../../map/xinjiang.json";
import xizang from "../../map/xizang.json";
import yunnan from "../../map/yunnan.json";
import zhejiang from "../../map/zhejiang.json";
import qinghai from "../../map/qinghai.json";
import henan from "../../map/henan.json";
import heilongjing from "../../map/heilongjing.json";
import hubei from "../../map/hubei.json";
import shanghai from "../../map/shanghai.json";
import taiwan from "../../map/taiwan.json";
export default {
  name: "mapContent",
  props: {
    province: {
      // 省份
      type: String,
      default: "",
    },
    city: {
      // 城市
      type: String,
      default: "",
    },
  },
  data() {
    return {
      leaderboardList: "", // 积分榜数据
      schoolId: "",
      styleObj: { width: "9.18rem", height: "5.59rem" },
      styleObj1: { width: "4.19rem", height: "4.5rem" },
      isMapChina: false,
      optionMap: {},
      value: "中国", //初始化为世界地图
      // province: "", // 默认地址
      // city: "", // 默认城市
      myChart: null, //初始化地图渲染器
      //   注册地图json
      jsonMap: {
        中国: china,
        北京市: beijing,
        山西省: shanxi,
        安徽省: anhui,
        重庆市: chongqing,
        福建省: fujian,
        甘肃省: gansu,
        广东省: guangdong,
        广西壮族自治区: guangxi,
        贵州省: guizhou,
        海南省: hainan,
        河北省: hebei,
        湖南省: hunan,
        江西省: jiangxi,
        江苏省: jiangsu,
        吉林省: jilin,
        辽宁省: liaoning,
        宁夏回族自治区: ningxia,
        内蒙古自治区: nmeng,
        陕西省: sanxi,
        山东省: shandong,
        四川省: sichuan,
        天津市: tianjing,
        新疆维吾尔自治区: xinjiang,
        西藏自治区: xizang,
        云南省: yunnan,
        浙江省: zhejiang,
        青海省: qinghai,
        河南省: henan,
        黑龙江省: heilongjing,
        湖北省: hubei,
        上海市: shanghai,
        台湾省: taiwan,
      },
      chinaData: [],
    };
  },
  created() {
    //循环注册地图
    for (let index in this.jsonMap) {
      this.$echarts.registerMap(index, this.jsonMap[index]);
    }
  },
  mounted() {
    //    初始化地图
    if (this.$store.state.superviseId) {
      this.value = "中国";
    } else {
      console.log(this.$store.state.schoolInfo.atteSchool.province);
      this.value = this.$store.state.schoolInfo.atteSchool.province;
      this.isMapChina = true;
    }
    //    获取地址
    this.schoolId = this.$store.state.userInfo.schoolId;
    this.province = this.$store.state.schoolInfo.atteSchool.province;
    this.city = this.$store.state.schoolInfo.atteSchool.trueCity;
    this.getRankList(0, this.province, this.city);
    this.getRankingAreaScoreList(this.value);
    //积分
  },
  //   更新数据
  watch: {
    value(newVal) {
      let erd = elementResizeDetectorMaker();
      let that = this;
      erd.listenTo(document.getElementById("map"), () => {
        that.$nextTick(() => {
          //使echarts尺寸重置
          that.$echarts.init(document.getElementById("map")).resize();
        });
      });
      for (const key in this.jsonMap) {
        console.log(key);
        if (key == newVal) {
          that.getRankingAreaScoreList(that.value);
        }
      }
      //this.setData(newVal);
    },
    province(newVal) {
      console.log(newVal, "新的城市再次");
      this.getRankingAreaScoreList(newVal);
      this.getRankList(0, this.province);
    },
    city(newVal) {
      this.getRankList(0, this.province, newVal);
    },
  },
  //销毁地图实例
  beforeDestroy() {
    if (!this.myChart) {
      return;
    }
    this.myChart.dispose();
    this.myChart = null;
  },
  methods: {
    //    点击返回
    bindBackMap() {
      console.log(11111111111111);

      if (this.value != "中国") {
        this.$set(this.styleObj, "width", "9.18rem");
        this.$set(this.styleObj, "height", "5.19rem");
        this.getRankingAreaScoreList("中国");
        this.isMapChina = false;
      }
    },
    // 获取区域积分
    getRankingAreaScoreList(value) {
      this.loading = true;
      this.api.zhSchool.getRankingAreaScoreList().then((res) => {
        console.log(res, "1111111111111111111111111111");
        var valueData = [];
        if (res.data.cityIntegralList) {
          res.data.cityIntegralList.forEach((item) => {
            var item1 = {
              name: item.city,
              value: item.integralTotal,
              label: {
                normal: {
                  show: false,
                  padding: [10, 10],
                  borderColor: "#FFE196",
                  borderType: "solid",
                  borderWidth: 2,
                  borderRadius: 50,
                  width: 30,
                  height: 30,
                  formatter: function (params) {
                    return params.value; //地图上展示文字 + 数值
                  },
                },
              },
            };
            if (item.city == this.city) {
              item1.label.normal.show = true;
            }
            valueData.push(item1);
          });
        }
        this.chinaConfigure(value, valueData);
      });
    },
    //    获取排行榜
    getRankList(rankingType, province, city) {
      let params = {
        schoolId: this.schoolId,
        rankingType: rankingType,
        province: province,
        city: city,
      };
      console.log(params);
      // this.loading = true;
      // this.api.school.findTopByType(params).then((res) => {
      //   console.log(res, "获取积分榜");
      //   this.leaderboardList = res.data.rankingList.splice(0, 10);
      // });
      this.api.zhSchool.geteRankingAreaList().then((res) => {
        this.leaderboardList = res.data.currentCitySchool.splice(0, 10);
      });
    },
    //    获取地图
    chinaConfigure(area, valueData) {
      console.log(valueData, "2222222222222222");
      this.myChart = this.$echarts.init(document.getElementById("map")); //这里是为了获得容器所在位置
      window.onresize = () => {
        this.myChart.resize();
      };
      this.optionMap = {
        showLegendSymbol: true,
        tooltip: {
          trigger: "item",
          textStyle: {
            fontSize: 14,
            lineHeight: 22,
          },
          position: (point) => {
            // 固定在顶部
            return [point[0] + 50, point[1] - 20];
          },
          // 如果需要自定义 tooltip样式，需要使用formatter

          // formatter: () => {
          //   return `<div style="font-size:14px;background: red;">111111111111</div>`;
          // },
        },
        series: [
          {
            layoutSize: "100",
            name: "积分",
            type: "map",
            aspectScale: 0.85, //长宽比
            zoom: 1.2,
            mapType: area, // 自定义扩展图表类型
            left: "center",
            top: "center",
            itemStyle: {
              normal: {
                color: "#333333",
                areaColor: "#7ad4ff70",
                borderColor: "rgba(0,242,252,.3)",
                borderWidth: 1,
                shadowBlur: 7,
                shadowColor: "#00f2fc",
              },
              emphasis: {
                areaColor: "#7ad4ff90",
                borderColor: "rgba(0,242,252,.6)",
                borderWidth: 2,
                shadowBlur: 10,
                shadowColor: "#00f2fc",
              },
            },
            label: {
              formatter: (params) => `${params.name}`,
              show: false,
              position: "insideRight",
              textStyle: {
                fontSize: 14,
                color: "#efefef",
              },
              emphasis: {
                textStyle: {
                  color: "#fff",
                },
              },
            },
            data: valueData,
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            symbolSize: 7,
            effectType: "ripple",
            legendHoverLink: false,
            showEffectOn: "render",
            rippleEffect: {
              period: 4,
              scale: 2.5,
              brushType: "stroke",
            },
            zlevel: 1,
            itemStyle: {
              normal: {
                color: "#99FBFE",
                shadowBlur: 5,
                shadowColor: "#fff",
              },
            },
            data: valueData,
          },
        ],
      };

      this.myChart.setOption(this.optionMap, true);

      if (this.$store.state.superviseId) {
        this.myChart.on("click", (params) => {
          // 点击函数
          console.log(this.jsonMap, "11111111111111");
          this.value = params.name;
          this.isMapChina = true;
          this.$set(this.styleObj, "width", "4.19rem");
          this.$set(this.styleObj, "height", "4.5rem");
          this.getRankList(
            0,
            this.$store.state.schoolInfo.atteSchool.province,
            params.name
          );
          this.city = params.name;
          // this.setData(
          //   this.$store.state.schoolInfo.atteSchool.province,
          //   params.name
          // );
          //this.myChart.setOption(this.optionMap, true);
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.map-char {
  display: flex;
  justify-content: center;
  align-items: center;
  height:100%;
}
.map-right{
height:100%;}
.rand-left {
  width: 330px;
  height: 100%;
  padding-left: 6px;
  padding-right: 6px;
  background: url(../../../../assets/img/datav/full/rand_bg.png) no-repeat;
  background-size: 100% 100%;
  overflow: auto;
  h2 {
    text-align: center;
    padding-top: 9px;
    font-size: 16px;
    color: #85b6ca;
    font-weight: bold;
  }
  .rand-list-title {
    height: 40px;
    display: flex;
    align-items: center;
    li {
      flex: 1;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #85b6ca;
      text-align: center;
    }
  }
  .rand-list-item {
    height: 36px;
    display: flex;
    align-items: center;
    li {
      flex: 1;
      text-align: center;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #bbbbbb;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .rand-list-item:nth-child(odd) {
    background: #ffffff10;
  }
}
#map{
width:100%;
height:100% !important;}
</style>