<!--
 * @Descripttion: 今天，你进步了吗？
 * @version: V1.0
 * @Author: lily
 * @Date: 2022-05-02 15:24:02
 * @LastEditors: lily
 * @LastEditTime: 2022-05-09 15:07:59
-->
<template>
  <div class="ranking">

    <component :is="currentRole" />

  </div>
</template>
<script>
// 海洋模式
import oceanRanking from "../html/ocean/oceanRanking.vue";
// 星空模式
import starryRanking from "../html/starry/starryRanking.vue";
export default {
  components: {
    oceanRanking,
    starryRanking,
  },
  data() {
    return {
      currentRole: "",
    };
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // 获取默认智慧风格
    this.getStyle();
  },
  methods: {
    getStyle() {
      this.api.zhSchool
        .schoolDetail(this.$store.state.userInfo.schoolId)
        .then((res) => {
          if (res.errCode == 0) {
            if (res.data.wisdomScreenStyleId == 1) {
              this.currentRole = "starryRanking";
            } else if (res.data.wisdomScreenStyleId == 2) {
              this.currentRole = "oceanRanking";
            }else{
                this.currentRole = "starryRanking";
            }
          }
          console.log(res, "1111111111111");
        });
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
  .ranking {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
</style>
