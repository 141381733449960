<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="3rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "../../../common/echart/index.vue";
export default {
  props: {
    type: {
      // 类型
      type: String,
      default: "1",
    },
    gradeId: {
      // 班级
      type: String,
      default: "",
    },
    gradeType: {
      // 班级类型
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      options: {
          
      },
      

      // 定义颜色
      colorList: {
        linearYtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#B4EAEB",
            },
            {
              offset: 1,
              color: "#B4EAEB",
            },
          ],
        },
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(35,184,210,.2)",
            },
            {
              offset: 1,
              color: "rgba(35,184,210,0)",
            },
          ],
        },
      },
      testType: "1",
    };
  },
  components: {
    Echart,
  },
  mounted() {
    this.changeOptions([0, 0, 0, 0, 0, 0, 0, 0]);
    this.geteRankingTZTestRate();
  },
  watch: {
    type(newval) {
      this.testType = newval;
      if (newval == 1) {
        this.geteRankingTZTestRate();
      } else {
        this.geteRankingSportTestRate();
      }
    },
    gradeId(newval) {
      this.gradeId = newval;
      if (this.type == 1) {
        this.geteRankingTZTestRate();
      } else {
        this.geteRankingSportTestRate();
      }
    },
    gradeType(newval) {
      this.gradeType = newval;
    },
  },
  methods: {
    // 获取体质数据
    geteRankingTZTestRate() {
      this.api.zhSchool
        .geteRankingTZTestRate({
          gradeId: this.gradeId,
        })
        .then((res) => {
          if (res.data) {
            var values = [];
            for (let i = 0; i < res.data.length; i++) {
              values.push(res.data[i].testAvgScore);
            }
            this.changeOptions(values);
          }
        });
    },
    changeOptions(values) {
      this.options = {
        grid: {
    show:false,
   left:"5%",
    top: "5%",
    right: "5%",
    bottom: "1%",
   
    
},

        tooltip: {
          trigger: "item",
        },
        radar: {
          center: ["45%", "55%"],
          radius: "60%",
          name: {
            color: "#fff",
          },
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              color: this.colorList.linearYtoG,
              opacity: 0.6,
            },
          },
          splitLine: {
            lineStyle: {
              color: this.colorList.linearYtoG,
              opacity: 0.6,
            },
          },
          splitArea: {
            areaStyle: {
              color: "#fff",
              opacity: 0.1,
              shadowBlur: 25,
              shadowColor: "#000",
              shadowOffsetX: 0,
              shadowOffsetY: 5,
            },
          },
          indicator: [
            {
              name: "身高/cm",
              max: 150,
              color: "#A3D3E3",
            },
            {
              name: "体重/kg",
              max: 45,
              color: "#A3D3E3",
            },
            {
              name: "视力",
              max: 5.3,
              color: "#A3D3E3",
            },
            {
              name: "臂展/cm",
              max: 180,
              color: "#A3D3E3",
            },
            {
              name: "肺活量/ml",
              max: 2000,
              color: "#A3D3E3",
            },
            {
              name: "胸围/cm",
              max: 110,
              color: "#A3D3E3",
            },
            // {
            //   name: "体脂率/%",
            //   max: 40,
            //   color: "#A3D3E3",
            // },
            {
              name: "坐姿体前屈/cm",
              max: 50,
              color: "#A3D3E3",
            },
          ],
        },
        series: [
          {
            name: "体质测试",
            type: "radar",
            symbolSize: 0,
            data: [
              {
                value: values,
                itemStyle: {
                  normal: {
                    color: "#B4EAEB",
                  },
                },
                lineStyle: {
                  normal: {
                    opacity: 0,
                  },
                },
                areaStyle: {
                  normal: {
                    color: "#96fcf98c",
                    shadowBlur: 25,
                    shadowColor: "#B4EAEB",
                    shadowOffsetX: 0,
                    shadowOffsetY: 10,
                    opacity: 1,
                  },
                },
              },
            ],
          },
        ],
      };
    },
    // 获取运动数据
    geteRankingSportTestRate() {
      this.api.zhSchool
        .geteRankingSportTestRate({
          gradeId: this.gradeId,
        })
        .then((res) => {
          var values = [];
          if (res.data) {
            for (let i = 0; i < res.data.length; i++) {
              values.push(res.data[i].testAvgScore);
            }
            // 大班
            if (this.gradeType == "0") {
              this.changeOptions1(values);
              // 中班
            } else if (this.gradeType == "1") {
              this.changeOptions2(values);
            } else {
              this.changeOptions3(values);
            }
          }
        });
    },
    changeOptions1(values) {
      this.options = {
        tooltip: {
          trigger: "item",
        },
        radar: {
          center: ["50%", "50%"],
          radius: "60%",
          name: {
            color: "#fff",
          },
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              color: this.colorList.linearYtoG,
              opacity: 0.6,
            },
          },
          splitLine: {
            lineStyle: {
              color: this.colorList.linearYtoG,
              opacity: 0.6,
            },
          },
          splitArea: {
            areaStyle: {
              color: "#fff",
              opacity: 0.1,
              shadowBlur: 25,
              shadowColor: "#000",
              shadowOffsetX: 0,
              shadowOffsetY: 5,
            },
          },
          indicator: [
            {
              name: "跑动",
              max: 60,
              color: "#A3D3E3",
            },
            {
              name: "跳跃",
              max: 60,
              color: "#A3D3E3",
            },
            {
              name: "敏捷",
              max: 90,
              color: "#A3D3E3",
            },
            {
              name: "平衡",
              max: 180,
              color: "#A3D3E3",
            },
            {
              name: "协调",
              max: 90,
              color: "#A3D3E3",
            },
            {
              name: "力量",
              max: 120,
              color: "#A3D3E3",
            },
            {
              name: "物体控制-手",
              max: 9,
              color: "#A3D3E3",
            },
            {
              name: "物体控制-脚",
              max: 180,
              color: "#A3D3E3",
            },
          ],
        },
        series: [
          {
            name: "运动测试",
            type: "radar",
            symbolSize: 0,
            data: [
              {
                value: values,
                itemStyle: {
                  normal: {
                    color: "#B4EAEB",
                  },
                },
                lineStyle: {
                  normal: {
                    opacity: 0,
                  },
                },
                areaStyle: {
                  normal: {
                    color: "#96fcf98c",
                    shadowBlur: 25,
                    shadowColor: "#B4EAEB",
                    shadowOffsetX: 0,
                    shadowOffsetY: 10,
                    opacity: 1,
                  },
                },
              },
            ],
          },
        ],
      };
    },
    changeOptions2(values) {
      this.options = {
        tooltip: {
          trigger: "item",
        },
        radar: {
          center: ["50%", "50%"],
          radius: "60%",
          name: {
            color: "#fff",
          },
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              color: this.colorList.linearYtoG,
              opacity: 0.6,
            },
          },
          splitLine: {
            lineStyle: {
              color: this.colorList.linearYtoG,
              opacity: 0.6,
            },
          },
          splitArea: {
            areaStyle: {
              color: "#fff",
              opacity: 0.1,
              shadowBlur: 25,
              shadowColor: "#000",
              shadowOffsetX: 0,
              shadowOffsetY: 5,
            },
          },
          indicator: [
            {
              name: "跑动",
              max: 60,
              color: "#A3D3E3",
            },
            {
              name: "跳跃",
              max: 60,
              color: "#A3D3E3",
            },
            {
              name: "敏捷",
              max: 90,
              color: "#A3D3E3",
            },
            {
              name: "平衡",
              max: 80,
              color: "#A3D3E3",
            },
            {
              name: "协调",
              max: 90,
              color: "#A3D3E3",
            },
            {
              name: "力量",
              max: 120,
              color: "#A3D3E3",
            },
            {
              name: "物体控制-手",
              max: 10,
              color: "#A3D3E3",
            },
            {
              name: "物体控制-脚",
              max: 90,
              color: "#A3D3E3",
            },
          ],
        },
        series: [
          {
            name: "运动测试",
            type: "radar",
            symbolSize: 0,
            data: [
              {
                value: values,
                itemStyle: {
                  normal: {
                    color: "#B4EAEB",
                  },
                },
                lineStyle: {
                  normal: {
                    opacity: 0,
                  },
                },
                areaStyle: {
                  normal: {
                    color: "#96fcf98c",
                    shadowBlur: 25,
                    shadowColor: "#B4EAEB",
                    shadowOffsetX: 0,
                    shadowOffsetY: 10,
                    opacity: 1,
                  },
                },
              },
            ],
          },
        ],
      };
    },
    changeOptions3(values) {
      this.options = {
        tooltip: {
          trigger: "item",
        },
        radar: {
          center: ["50%", "50%"],
          radius: "60%",
          name: {
            color: "#fff",
          },
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              color: this.colorList.linearYtoG,
              opacity: 0.6,
            },
          },
          splitLine: {
            lineStyle: {
              color: this.colorList.linearYtoG,
              opacity: 0.6,
            },
          },
          splitArea: {
            areaStyle: {
              color: "#fff",
              opacity: 0.1,
              shadowBlur: 25,
              shadowColor: "#000",
              shadowOffsetX: 0,
              shadowOffsetY: 5,
            },
          },
          indicator: [
            {
              name: "跑动",
              max: 60,
              color: "#A3D3E3",
            },
            {
              name: "跳跃",
              max: 60,
              color: "#A3D3E3",
            },
            {
              name: "敏捷",
              max: 90,
              color: "#A3D3E3",
            },
            {
              name: "平衡",
              max: 70,
              color: "#A3D3E3",
            },
            {
              name: "协调",
              max: 90,
              color: "#A3D3E3",
            },
            {
              name: "力量",
              max: 120,
              color: "#A3D3E3",
            },
            {
              name: "物体控制-手",
              max: 10,
              color: "#A3D3E3",
            },
            {
              name: "物体控制-脚",
              max: 90,
              color: "#A3D3E3",
            },
          ],
        },
        series: [
          {
            name: "运动测试",
            type: "radar",
            symbolSize: 0,
            data: [
              {
                value: values,
                itemStyle: {
                  normal: {
                    color: "#B4EAEB",
                  },
                },
                lineStyle: {
                  normal: {
                    opacity: 0,
                  },
                },
                areaStyle: {
                  normal: {
                    color: "#96fcf98c",
                    shadowBlur: 25,
                    shadowColor: "#B4EAEB",
                    shadowOffsetX: 0,
                    shadowOffsetY: 10,
                    opacity: 1,
                  },
                },
              },
            ],
          },
        ],
      };
    },
  },
};
</script>