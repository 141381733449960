<template>
  <div>
    <Chart :type="type" :gradeId="gradeId" :gradeType="gradeType" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
export default {
  props: {
    type: {
      // 类型
      type: String,
      default: "1",
    },
    gradeId: {
      // 班级Id
      type: String,
      default: "",
    },
    gradeType: {
      // 班级类型
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      drawTiming: null,
      cdata: {
        radarData: [],
        maxData: 12000,
        value: [],
      },
    };
  },
  components: {
    Chart,
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>